<template>
  <div class="d-flex align-start">
    <v-radio-group
      ref="input"
      class="mt-0"
      row
      v-model="value"
      :disabled="disabled"
      :rules="mandatoryFieldValidationRule"
    >
      <template
        v-if="boolean"
      >
        <v-radio
          :value="true"
          :label="getLabel('true')"
          :tabindex="inputTabIndex"
        ></v-radio>
        <v-radio
          :value="false"
          :label="getLabel('false')"
          :tabindex="inputTabIndex"
        ></v-radio>
      </template>
      <template
        v-else
      >
        <v-radio
          v-for="(choice, index) in choices"
          :key="`choice-${index}`"
          :value="choice"
          :label="getLabel(choice)"
          :tabindex="inputTabIndex"
        ></v-radio>
      </template>
    </v-radio-group>
  </div>
</template>

<script>
import BdmsControlBaseInput from './BdmsControlBaseInput'

export default {
  name: 'BdmsControlRadioGroup',
  extends: BdmsControlBaseInput,
  props: {
    boolean: {
      type: Boolean,
      default: false
    },
    choices: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getLabel (value) {
      const regex = /.[0-9]+/g
      const labelPath = this.field.replace(regex, '')
      return this.$t(`global.components.contractSummaryForm.${this.formSegment}.labels.${labelPath}Radio.${value}`)
    }
  },
  computed: {
    mandatoryFieldValidationRule () {
      return this.required ? [(v) => !!v || this.$t('global.form.errorMessages.fields.generic.required')] : []
    }
  }
}
</script>
