<template>
  <div>
    <v-stepper
      class="mt-12"
      v-model="currentStep"
      vertical
      ref="stepper"
    >
      <template
        v-for="(form, key, index) in partialForms"
      >
        <v-stepper-step
          :complete="currentStep > (index + 1)"
          :step="index + 1"
          :editable="currentStep >= (index + 1)"
          :key="`step-${index + 1}`"
        >
          {{ $t(`global.components.contractSummaryForm.${key}.headings.general`) }}
        </v-stepper-step>
        <v-stepper-content
          :step="index + 1"
          :key="`step-content-${index + 1}`"
        >
          <component
            :is="form"
            @submit="handleSubmit"
            @save="handleSave"
          ></component>
        </v-stepper-content>
      </template>
    </v-stepper>
  </div>
</template>

<script>
import CreateContractSummaryBaseSegmentForm from './partial-forms/CreateContractSummaryBaseSegmentForm'
import CreateContractSummaryServiceSegmentForm from './partial-forms/CreateContractSummaryServiceSegmentForm'
import CreateContractSummaryBandwidthSegmentForm from './partial-forms/CreateContractSummaryBandwidthSegmentForm'
import CreateContractSummaryPriceSegmentForm from './partial-forms/CreateContractSummaryPriceSegmentForm'
import CreateContractSummaryPeriodSegmentForm from './partial-forms/CreateContractSummaryPeriodSegmentForm'
import CreateContractSummaryDisabilitySegmentForm from './partial-forms/CreateContractSummaryDisabilitySegmentForm'
import CreateContractSummaryOtherSegmentForm from './partial-forms/CreateContractSummaryOtherSegmentForm'
import CreateContractSummarySubmitSegmentForm from './partial-forms/CreateContractSummarySubmitSegmentForm'

import { mapActions, mapGetters } from 'vuex'
import PageMixin from '@/mixins/page.mixin'

import cloneDeep from 'lodash/cloneDeep'
import mergeWith from 'lodash/mergeWith'
import findIndex from 'lodash/findIndex'

import { transformContractSummaryFormData } from '@/helpers/templates.helper'
import { scrollToElement } from '@/helpers/utility.helper'

export default {
  name: 'CreateContractSummaryForm',
  mixins: [
    PageMixin
  ],
  components: {
    CreateContractSummaryBaseSegmentForm,
    CreateContractSummaryServiceSegmentForm,
    CreateContractSummaryBandwidthSegmentForm,
    CreateContractSummaryPriceSegmentForm,
    CreateContractSummaryPeriodSegmentForm,
    CreateContractSummaryDisabilitySegmentForm,
    CreateContractSummaryOtherSegmentForm,
    CreateContractSummarySubmitSegmentForm
  },
  props: {
    templateUuid: {
      type: String,
      default: null
    },
    standalone: {
      type: Boolean,
      default: false
    }
  },
  provide () {
    return {
      standalone: this.standalone
    }
  },
  data () {
    return {
      currentStep: 0,
      partialForms: {
        baseSegment: 'CreateContractSummaryBaseSegmentForm',
        serviceSegment: 'CreateContractSummaryServiceSegmentForm',
        bandwidthSegment: 'CreateContractSummaryBandwidthSegmentForm',
        priceSegment: 'CreateContractSummaryPriceSegmentForm',
        periodSegment: 'CreateContractSummaryPeriodSegmentForm',
        disabilitySegment: 'CreateContractSummaryDisabilitySegmentForm',
        otherSegment: 'CreateContractSummaryOtherSegmentForm',
        submitSegment: 'CreateContractSummarySubmitSegmentForm'
      },
      scrollingEnabled: true
    }
  },
  mounted () {
    if (this.standalone) {
      this.resetErrorData()
      this.fetchDefaultValues()
        .then(() => {
          if (this.templateUuid) {
            this.getContractSummary(this.templateUuid)
              .then(() => {
                this.currentStep = 1
              })
          } else {
            this.currentStep = 1
          }
        })
    } else {
      this.currentStep = 1
    }
  },
  beforeDestroy () {
    this.reset()
  },
  methods: {
    ...mapActions('contractSummaries', ['setInitialData', 'setDefaultValues', 'setFormComplete', 'setControlDataComplete', 'setCurrentSegment', 'reset', 'setDone', 'createContractSummary', 'getContractSummary', 'resetErrorData', 'fetchDefaultValues']),
    submit () {
      this.$emit('submit', transformContractSummaryFormData(this.formData, this.controlData))
    },
    handleSubmit () {
      if (this.currentStep === Object.keys(this.partialForms).length) {
        this.submit()
      } else {
        this.currentStep = parseInt(this.currentStep) + 1
        setTimeout(() => {
          // set default values for empty form fields when form is collapsed
          const formData = cloneDeep(this.formData)
          const defaultValues = cloneDeep(this.defaultValues)
          const merged = mergeWith(
            {},
            defaultValues,
            formData,
            (a, b) => b === '' ? a : undefined
          )
          this.setFormComplete(merged)
        }, 250)
      }
    },
    handleSave ({ templateName }) {
      this.createContractSummary({ name: templateName, template: transformContractSummaryFormData(this.formData, this.controlData), callee: this._uid })
        .then((response) => {
          if (response.status === 200) {
            this.$emit('saved')
          }
        })
    },
    scrollToSegment (formSegment) {
      let index = findIndex(Object.keys(this.partialForms), (value) => {
        return value === formSegment
      })
      if (index !== -1) {
        index++
        if (index < this.currentStep) {
          this.currentStep = index
        }
      }
    }
  },
  computed: {
    ...mapGetters('contractSummaries', ['formData', 'currentSegment', 'controlData', 'errorData', 'contractSummaryTemplates', 'contractSummaryTemplate', 'loading', 'defaultPriceTableEntries', 'done', 'defaultValues']),
    saveTemplateButtonText () {
      return this.$t('global.components.contractSummaryForm.saveTemplateButton')
    },
    submitButtonText () {
      return this.$t('global.components.contractSummaryForm.submitButton')
    }
  },
  watch: {
    currentStep (newValue) {
      const parsedValue = parseInt(newValue)
      this.setDone(parsedValue === Object.keys(this.partialForms).length)
      // set current segment
      if (parsedValue <= Object.keys(this.partialForms).length) {
        const currentSegment = Object.keys(this.partialForms)[parsedValue - 1]
        this.setCurrentSegment(currentSegment)
      }
      // scroll to active stepper step
      if (this.scrollingEnabled) {
        setTimeout(() => {
          const activeStepHeading = this.$refs.stepper.steps[parsedValue - 1].$el
          scrollToElement(activeStepHeading)
        }, 700)
      }
      this.scrollingEnabled = true
    },
    currentSegment (newValue) {
      this.scrollToSegment(newValue)
    },
    errorData (newValue) {
      const lastSegment = Object.keys(this.partialForms).reverse()[0]
      if (lastSegment === this.currentSegment) {
        let scrollingEnabled = true
        Object.keys(newValue)
          .forEach((key) => {
            if (!this.partialForms[key]) {
              scrollingEnabled = false
            }
          })

        let formSegment = ''
        Object.keys(this.partialForms)
          .forEach((formKey) => {
            if (newValue[formKey]) {
              const errors = Object.keys(newValue[formKey])
                .reduce((acc, fieldKey) => {
                  return acc + newValue[formKey][fieldKey]
                }, '')
              if (errors !== '' && formSegment === '') {
                formSegment = formKey
              }
            }
          })
        if (formSegment !== '') {
          this.scrollingEnabled = scrollingEnabled
          this.setCurrentSegment(formSegment)
        }
      }
    }
  }
}
</script>

<style scoped>
>>> .v-stepper__label {
  line-height: 1.5em
}

>>> .v-stepper--vertical .v-stepper__step {
  padding: 1rem 1rem 0.75rem
}

>>> .v-stepper--vertical .v-stepper__content {
  margin: -8px -36px -16px 24px !important
}

@media (min-width: 960px) {
  >>> .v-stepper--vertical .v-stepper__step {
    padding: 1.5rem 1.5rem 1rem
  }
  >>> .v-stepper--vertical .v-stepper__content {
    margin: -8px -36px -16px 36px !important
  }
}
</style>
