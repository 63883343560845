<template>
  <div>
    <div class="mb-8">
      <page-header :heading="pageHeading" :sub-heading="pageSubHeading"></page-header>
    </div>

    <div
      class="my-8 d-flex justify-end"
    >
      <v-btn
        @click="handleBack"
        color="primary"
        class="white--text"
        v-text="backButtonText"
      ></v-btn>
    </div>

    <confirmation-dialog
      ref="leaveRouteConfirmationDialog"
      xs-column
      v-model="leaveRouteConfirmationDialog"
      :title="leaveRouteConfirmationDialogTitle"
      :text="leaveRouteConfirmationDialogText"
      :cancel="leaveRouteConfirmationDialogCancelText"
      :confirm="leaveRouteConfirmationDialogConfirmText"
      confirm-color="red"
      @cancel="cancelLeaveRoute"
      @confirm="confirmLeaveRoute"
    ></confirmation-dialog>

    <form-builder
      ref="form"
      :config="formConfig"
      :data="formData"
      :errors="formErrors"
      :rules="formRules"
      :transformers="formTransformers"
      @change="markAsDirty"
      @reset="handleReset"
    >
      <template #form.footer>
        <v-btn
          :disabled="baseDataReady"
          color="primary"
          @click="validateBaseForm"
        >
          {{ continueButton }}
        </v-btn>
      </template>
    </form-builder>

    <div class="mt-16" v-show="baseDataReady" ref="contractSummaryFormWrapper">
      <help-dialog
        tag="h3"
        path="components.form.sections.contractSummaryTemplate"
        css-classes="text-h6 mb-2"
      ></help-dialog>

      <v-select
        v-model="contractSummaryTemplate"
        :items="contractSummaryTemplateItems"
        :label="contractSummaryTemplateLabel"
        :placeholder="contractSummaryTemplatePlaceholder"
        item-text="label"
        hide-details="auto"
        outlined
        clearable
      ></v-select>

      <div
        class="mt-4"
        v-if="showTemplateActions"
      >
        <rendered-preview
          v-model="preview"
          :title="previewDialogTitle"
        ></rendered-preview>
        <v-row class="justify-space-between">
          <v-col cols="12" sm="auto">
            <v-btn
              @click="handlePreviewTemplate"
              color="primary"
              :disabled="showSummaryContractForm || contractSummaryReady || editTemplateButtonLoading"
            >
              {{ previewTemplateButton }}
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="auto"
          >
            <v-row>
              <v-col cols="12" sm="auto">
                <v-btn
                  @click="handleSkipTemplateForm"
                  color="secondary"
                  :disabled="showSummaryContractForm || contractSummaryReady || editTemplateButtonLoading"
                >
                  {{ skipTemplateFormButton }}
                </v-btn>
              </v-col>
              <v-col cols="12" sm="auto">
                <v-btn
                  @click="handleEditTemplate"
                  color="primary"
                  :loading="editTemplateButtonLoading"
                  :disabled="showSummaryContractForm || contractSummaryReady"
                >
                  {{ editTemplateButton }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>

      <create-contract-summary-form
        v-if="showSummaryContractForm"
        :template-uuid="contractSummaryTemplate"
      ></create-contract-summary-form>

      <div class="mt-8">
        <v-btn
          ref="submitButton"
          :disabled="!formsAreReady"
          color="primary"
          @click="showDialog"
        >{{ submitButton }}</v-btn>
      </div>

      <confirmation-dialog
        xs-column
        v-model="dialog"
        :loading="loadingCreate"
        :title="dialogTitle"
        :text="this.confirmationDialogTexts"
        :cancel="dialogCancelButton"
        :confirm="dialogConfirmButton"
        @confirm="handleConfirm"
      ></confirmation-dialog>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import { addDays } from 'date-fns'
import { mapActions, mapGetters } from 'vuex'

import i18n from '@/mixins/i18n.mixin'
import Page from '@/mixins/page.mixin'
import LeaveRouteAware from '@/mixins/leaveRouteAware.mixin'

import { getHttpBadRequestErrorDetails, getHttpValidationErrorDetails } from '@/helpers/api.helper'
import { normalizeISO8601Timezone } from '@/helpers/datetime.helper'
import { required, min, max, email, uuid } from '@/helpers/validators.helpers'
import { transformContractSummaryFormData } from '@/helpers/templates.helper'
import { scrollToElement } from '@/helpers/utility.helper'
import Customer from '@/types/Customer'

import ConfirmationDialog from '@/components/ConfirmationDialog'
import CreateContractSummaryForm from '@/components/contract-summaries/CreateContractSummaryForm'
import FormBuilder from '@/components/form/FormBuilder'
import HelpDialog from '@/components/HelpDialog'
import PageHeader from '@/components/PageHeader'
import RenderedPreview from '@/components/contract-summaries/preview/RenderedPreview'

export default {
  name: 'Create',
  components: {
    HelpDialog,
    ConfirmationDialog,
    CreateContractSummaryForm,
    FormBuilder,
    PageHeader,
    RenderedPreview
  },
  mixins: [
    i18n,
    Page,
    LeaveRouteAware
  ],
  data () {
    return {
      preview: false,
      editTemplateButtonLoading: false,
      contractSummaryTemplate: null,
      showTemplateActions: false,
      showSummaryContractForm: false,
      baseDataReady: false,
      dialog: false,
      formData: {
        givenName: '',
        familyName: '',
        email: '',
        customerId: '',
        customerType: null,
        validity: this.validityTermSetting,
        precedingContractUuid: '',
        mailTemplateUuid: '',
        documentFolderUuid: ''
      },
      formErrors: {
        givenName: '',
        familyName: '',
        email: '',
        customerId: '',
        validity: '',
        precedingContractUuid: '',
        mailTemplateUuid: '',
        documentFolderUuid: '',
        comment: ''
      },
      formRules: {
        givenName: [
          required
        ],
        familyName: [
          required
        ],
        email: [
          email
        ],
        customerId: [
          [max, { max: 255 }]
        ],
        customerType: [
          required
        ],
        validity: [
          required,
          [min, { min: 2, number: true }]
        ],
        precedingContractUuid: [
          uuid
        ],
        mailTemplateUuid: [
          [required, { field: 'email' }]
        ],
        documentFolderUuid: [
          required
        ]
      },
      formTransformers: {
        validity: (value) => parseInt(value)
      },
      confirmationDialogTexts: []
    }
  },
  async created () {
    scrollToElement(document.body)
    await this.fetchContractSummaryTemplates()
    await this.fetchMailTemplates()
    await this.getDocumentFolders()

    if (!this.settingsLoaded) {
      await this.fetchSettings()
    }

    this.reset()
    this.resetErrorData()
    await this.fetchDefaultValues()
  },
  beforeDestroy () {
    this.reset(true)
  },
  methods: {
    ...mapActions('contractSummaries', {
      fetchContractSummaryTemplates: 'listContractSummaries',
      resetContractSummary: 'reset',
      setContractSummaryReady: 'setDone'
    }),
    ...mapActions('contractSummaries', ['resetErrorData', 'getContractSummary', 'fetchDefaultValues']),
    ...mapActions('documentFolders', ['getDocumentFolders']),
    ...mapActions('mailTemplates', ['fetchMailTemplates']),
    ...mapActions('messageQueue', ['queueError', 'queueSuccess']),
    ...mapActions('requests', ['createRequest']),
    ...mapActions('settings', ['fetchSettings']),
    showDialog () {
      this.getConfirmationDialogTexts()

      this.dialog = true
    },
    async handleConfirm () {
      const data = this.getRequestDataFromForms()

      let success = false

      await this.createRequest({
        ...data,
        handlers: {
          '2xx': () => {
            success = true
          },
          400: (response) => {
            const message = getHttpBadRequestErrorDetails(response)

            if (message) {
              const { groups } = message.match(/^preceding request (?<uuid>[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}) not found$/i)
              const uuid = groups?.uuid

              if (!uuid) {
                return message
              }

              const errorMessage = this.$t(`${this.pageTranslationKey}.components.form.messages.errors.wrongPrecedingRequestUuid`, { uuid })

              this.$set(this.formErrors, 'precedingContractUuid', errorMessage)
              scrollToElement(this.$refs.form.$refs.precedingContractUuid)
              this.dialog = false

              return errorMessage
            }
          },
          422: (response) => {
            const errors = getHttpValidationErrorDetails(response)

            this.dialog = false

            if (errors) {
              errors.forEach(({ field, message }) => {
                let _field = field

                if (_field === 'surrogatCustomerUid') {
                  _field = 'customerId'
                }

                if (_field === 'predecessorEeccRequestUuid') {
                  _field = 'precedingContractUuid'
                }

                this.$set(this.formErrors, _field, message)
              })
            }

            this.scrollToForm()

            return this.$t('global.form.errorMessages.invalid')
          },
          '4xx': () => {
            this.dialog = false
            return this.$t('global.form.errorMessages.generic')
          }
        }
      })

      if (success) {
        this.watchRouteChange = false
        this.queueSuccess(this.successMessage)
        this.reset(true)
        this.dialog = false

        await this.$router.push({ name: 'requests' })
      } else {
        this.queueError(this.errorMessage)
      }
    },
    getRequestDataFromForms () {
      const {
        givenName,
        familyName,
        email,
        customerId,
        precedingContractUuid,
        mailTemplateUuid,
        documentFolderUuid,
        validity,
        customerType,
        comment
      } = cloneDeep(this.$refs.form.formData)

      const customerData = { givenName, familyName, isBusinessCustomer: Customer.isBusiness(customerType) }

      if (email) {
        customerData.email = email
      }

      const data = {
        customerData,
        documentFolderUuid,
        surrogatCustomerUid: customerId
      }

      if (validity) {
        const expiryDate = addDays(new Date(), validity)

        data.expiryDate = normalizeISO8601Timezone(expiryDate.toISOString())
      }

      if (precedingContractUuid) {
        data.precedingContractUuid = precedingContractUuid
      }

      if (mailTemplateUuid) {
        data.mailTemplateUuid = mailTemplateUuid
      }

      if (comment) {
        data.comment = comment
      }

      data.contractSummaryTemplate = transformContractSummaryFormData(this.contractSummaryData, this.contractSummaryControlData)

      return data
    },
    reset (resetContractSummary = false) {
      this.formData = {
        givenName: '',
        familyName: '',
        email: '',
        customerId: '',
        customerType: null,
        validity: this.validityTermSetting,
        precedingContractUuid: '',
        mailTemplateUuid: '',
        documentFolderUuid: ''
      }

      this.contractSummaryTemplate = null

      if (resetContractSummary) {
        this.resetContractSummary()

        this.contractSummaryTemplate = null
        this.setContractSummaryReady(false)
      }
    },
    validateBaseForm () {
      this.$refs.form.validate()

      if (this.$refs.form.isValid) {
        this.baseDataReady = true
      } else {
        // scroll to first invalid form field
        const formBuilder = this.$refs.form
        const fields = formBuilder.fields
          .filter((field) => field !== undefined)
        const index = fields.findIndex((field) => {
          return !formBuilder.$refs[field].valid
        })
        const field = formBuilder.$refs[fields[index]]
        if (field !== undefined) {
          scrollToElement(field)
        }
      }
    },
    markAsDirty () {
      this.contractSummaryTemplate = null
      this.baseDataReady = false
    },
    scrollToForm () {
      scrollToElement(this.$refs.form)
    },
    getDialogTextEmail (email) {
      return this.$tp('components.dialogs.create.textEmail', { email })
    },
    getConfirmationDialogTexts () {
      const { customerData } = this.getRequestDataFromForms()
      const email = customerData.email

      if (email) {
        this.confirmationDialogTexts = [this.dialogText, this.getDialogTextEmail(email)]
        return
      }

      this.confirmationDialogTexts = [this.dialogText, this.dialogTextNoEmail]
    },
    handleEditTemplate () {
      this.setContractSummaryReady(false)
      this.editTemplateButtonLoading = true
      this.getContractSummary(this.contractSummaryTemplate)
        .then(() => {
          this.editTemplateButtonLoading = false
          this.showSummaryContractForm = true
        })
    },
    handlePreviewTemplate () {
      this.getContractSummary(this.contractSummaryTemplate)
        .then(() => {
          this.preview = true
        })
    },
    handleSkipTemplateForm () {
      this.getContractSummary(this.contractSummaryTemplate)
        .then(() => {
          scrollToElement(this.$refs.submitButton)
          this.setContractSummaryReady(true)
        })
    },
    handleBack () {
      this.$router.push({ name: 'requests' })
    },
    handleReset (field) {
      this.$set(this.formErrors, field, '')
    }
  },
  computed: {
    ...mapGetters('contractSummaries', {
      contractSummaryTemplates: 'contractSummaryTemplates',
      contractSummaryReady: 'done',
      contractSummaryData: 'formData',
      contractSummaryControlData: 'controlData'
    }),
    ...mapGetters('documentFolders', ['documentFolders']),
    ...mapGetters('mailTemplates', ['mailTemplates']),
    ...mapGetters('requests', ['loadingCreate']),
    ...mapGetters('settings', {
      settingsLoaded: 'initialLoad',
      settings: 'settings'
    }),
    customerOptions () {
      return Customer.types.map((type) => ({ label: Customer.getTranslation(type), value: type }))
    },
    formConfig () {
      return [
        [
          [
            {
              component: 'text-type',
              props: {
                heading: this.customerDataSectionTitle,
                helpDialog: true,
                class: ['mb-n4']
              }
            }
          ]
        ],
        [
          [
            {
              name: 'givenName',
              component: 'v-text-field'
            },
            {
              name: 'familyName',
              component: 'v-text-field'
            },
            {
              name: 'customerType',
              component: 'v-radio-group',
              props: {
                required: true,
                options: this.customerOptions,
                class: ['mt-n2']
              }
            }
          ],
          [
            {
              name: 'customerId',
              component: 'v-text-field'
            },
            {
              name: 'email',
              component: 'v-text-field',
              props: {
                type: 'email'
              }
            }
          ]
        ],
        [
          [
            {
              component: 'text-type',
              props: {
                heading: this.contractDataSectionTitle,
                helpDialog: true,
                class: ['mb-2']
              }
            },
            {
              name: 'precedingContractUuid',
              component: 'v-text-field'
            }
          ],
          []
        ],
        [
          [
            {
              component: 'text-type',
              props: {
                heading: this.requestDataSectionTitle,
                helpDialog: true,
                class: ['mb-n4']
              }
            }
          ]
        ],
        [
          [
            {
              component: 'text-type',
              props: {
                subHeading: this.documentFolderSectionTitle,
                helpDialog: true,
                class: ['mb-2']
              }
            },
            {
              name: 'documentFolderUuid',
              component: 'v-autocomplete',
              props: {
                itemText: 'name',
                itemValue: 'uuid',
                items: this.documentFolders,
                disabled: !this.hasDocumentFolders
              }
            },
            {
              component: 'text-type',
              props: {
                subHeading: this.validitySectionTitle,
                helpDialog: true,
                class: ['mt-n2 mb-2']
              }
            },
            {
              name: 'validity',
              component: 'v-text-field',
              props: {
                type: 'number',
                min: 0,
                step: 1,
                suffix: this.validityFieldSuffix
              }
            },
            {
              component: 'text-type',
              props: {
                subHeading: this.mailTemplateSectionTitle,
                helpDialog: true,
                class: ['mt-n2 mb-2']
              }
            },
            {
              name: 'mailTemplateUuid',
              component: 'v-autocomplete',
              props: {
                itemText: 'name',
                itemValue: 'uuid',
                items: this.mailTemplates,
                disabled: !this.hasMailTemplates,
                clearable: true
              }
            }
          ],
          [
            {
              component: 'text-type',
              props: {
                subHeading: this.otherDataSectionTitle,
                helpDialog: true,
                class: ['mb-2']
              }
            },
            {
              name: 'comment',
              component: 'v-textarea'
            }
          ]
        ]
      ]
    },
    contractSummaryTemplateItems () {
      return [
        ...this.contractSummaryTemplates.map(({ name, uuid }) => ({ label: name, value: uuid })),
        { divider: true },
        { label: this.noTemplateOption, value: '' }
      ]
    },
    documentFolderItems () {
      return this.documentFolders.map(({ name, uuid }) => ({ label: name, value: uuid }))
    },
    hasDocumentFolders () {
      return this.documentFolders.length > 0
    },
    hasMailTemplates () {
      return this.mailTemplates.length > 0
    },
    validityTermSetting () {
      return this.settings?.validityTermDays ?? ''
    },
    formsAreReady () {
      return this.baseDataReady && this.contractSummaryReady
    },
    customerDataSectionTitle () {
      return 'components.form.sections.customerData'
    },
    validitySectionTitle () {
      return 'components.form.sections.validity'
    },
    contractDataSectionTitle () {
      return 'components.form.sections.contractData'
    },
    mailTemplateSectionTitle () {
      return 'components.form.sections.mailTemplate'
    },
    requestDataSectionTitle () {
      return 'components.form.sections.requestData'
    },
    otherDataSectionTitle () {
      return 'components.form.sections.otherData'
    },
    documentFolderSectionTitle () {
      return 'components.form.sections.documentFolder'
    },
    contractSummaryTemplateLabel () {
      return this.$tp('components.form.fields.contractSummaryTemplate.label')
    },
    contractSummaryTemplatePlaceholder () {
      return this.$tp('components.form.fields.contractSummaryTemplate.placeholder')
    },
    noTemplateOption () {
      return this.$tp('components.form.fields.contractSummaryTemplate.options.noTemplate')
    },
    dialogTitle () {
      return this.$tp('components.dialogs.create.title')
    },
    dialogText () {
      return this.$tp('components.dialogs.create.text')
    },
    dialogTextNoEmail () {
      return this.$tp('components.dialogs.create.textNoEmail')
    },
    dialogCancelButton () {
      return this.$tp('components.dialogs.create.buttons.cancel')
    },
    dialogConfirmButton () {
      return this.$tp('components.dialogs.create.buttons.confirm')
    },
    successMessage () {
      return this.$tp('components.messages.success')
    },
    errorMessage () {
      return this.$tp('components.messages.error')
    },
    submitButton () {
      return this.$tp('components.form.buttons.submit')
    },
    continueButton () {
      return this.$tp('components.form.buttons.continue')
    },
    validityFieldSuffix () {
      return this.$t('global.units.days')
    },
    editTemplateButton () {
      return this.$tp('components.form.buttons.editTemplate')
    },
    previewTemplateButton () {
      return this.$tp('components.form.buttons.previewTemplate')
    },
    skipTemplateFormButton () {
      return this.$tp('components.form.buttons.skipTemplateForm')
    },
    previewDialogTitle () {
      return this.$t('global.components.contractSummaryForm.previewDialogTitle')
    },
    backButtonText () {
      return this.$tp('components.buttons.backButton.text')
    }
  },
  watch: {
    contractSummaryTemplate (value) {
      this.setContractSummaryReady(false)
      this.resetContractSummary()
      if (!value && value !== '') {
        this.showTemplateActions = false
        this.showSummaryContractForm = false
        return
      }

      if (value === '') {
        this.showSummaryContractForm = false
        this.showTemplateActions = false

        this.$nextTick(() => {
          this.showSummaryContractForm = true
        })
      } else {
        this.showSummaryContractForm = false
        this.showTemplateActions = true
      }
    },
    baseDataReady (newValue) {
      if (newValue) {
        setTimeout(() => {
          scrollToElement(this.$refs.contractSummaryFormWrapper)
        }, 10)
      }
    }
  }
}
</script>
